// Initialize and add the map
let map;

async function initMap( el, mapId ) {

    var mapmarker = el.querySelector('.marker');
    var lat = parseFloat(mapmarker.dataset.lat);
    var lng = parseFloat(mapmarker.dataset.lng);

    const position = { lat: lat, lng: lng };

    var mapStyling = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#EBE9F2"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#dde6e8"
            },
            {
                "visibility": "on"
            }
        ]
    }
    ];

    const { Map } = await google.maps.importLibrary("maps");

    var mapArgs = {
        center: position,
        zoom: 16, 
        styles: mapStyling, 
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
    }

    map = new Map( el, mapArgs );

    new google.maps.Marker({
        position : position,
        map: map,
        icon: {
        path: 'M23 0.859375C10.2475 0.859375 0 11.4234 0 24.579C0 33.6997 11.0619 51.9475 17.8427 62.2765C20.314 66.041 25.686 66.041 28.1573 62.2765C34.9381 51.9475 46 33.6997 46 24.579C46 11.4234 35.7525 0.859375 23 0.859375ZM23 35.3573C17.1471 35.3573 12.5447 30.615 12.5447 24.5749C12.5447 18.5389 17.1431 13.7925 23 13.7925C28.8529 13.7925 33.4553 18.5348 33.4553 24.5749C33.4553 30.6149 28.853 35.3573 23 35.3573Z',
        fillColor: '#302683',
        fillOpacity: 1,
        anchor: { x: 12, y: 24 },
        },
    });
}

document.addEventListener('DOMContentLoaded', function() {
    document.querySelectorAll('.contact-page__map-google-map').forEach(function(element) {
        initMap(element, element.dataset.mapid);
    });
});
