import axios from "axios";

let userLocation = null;
const clickedIconColour = '#4bb9dc';
const defaultIconColour = '#312783';

async function getDistance(from, to) {
  const {spherical} = await google.maps.importLibrary("geometry");
  const fromLatLng = new google.maps.LatLng(from.lat, from.lng);
  const toLatLng = new google.maps.LatLng(to.lat, to.lng);
  const metersDistance = spherical.computeDistanceBetween(fromLatLng, toLatLng);

  return metersDistance / 1609;
}

function getGeocodeResult(address) {
  const Geocoder = new google.maps.Geocoder();
  const request = {address};
  return Geocoder.geocode(request);
}
/**
* initMap
*
* Renders a Google Map onto the selected jQuery element
*
* @date    22/10/19
* @since   5.8.6
*
* @param   nodeItem $el The jQuery element.
* @return  object The map instance.
*/
async function initMap( $el, mapId ) {
  const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
  // Find marker elements within map.
  var $markers = $el.querySelectorAll('.shop__marker');
  var infowindow = new google.maps.InfoWindow();

  // Styling
  var mapStyling = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#EBE9F2"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#dde6e8"
            },
            {
                "visibility": "on"
            }
        ]
    }
  ];
  
  // Create gerenic map.
  var mapArgs = {
    zoom              : parseInt($el.dataset.zoom) || 8,
    styles            : mapStyling,
    zoomControl       : true,
    mapTypeControl    : false,
    scaleControl      : false,
    streetViewControl : false,
    rotateControl     : false,
    fullscreenControl : false,
  };
  var map = new google.maps.Map( $el, mapArgs );

  // Add markers.
  map.markers = [];

  const successCallback = (position) => {
    userLocation = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    initMarker(
      {
        dataset: {
          shopId: 0,
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      },
      map
    );

    initAllMarkers(
      $markers,
      map,
      infowindow,
      mapId,
      userLocation
    );
    centerMap(map);
    createCardClickEvents(map);
  };

  const errorCallback = (error) => {
    console.log(error);
    initAllMarkers($markers, map, infowindow, mapId);
    centerMap(map);
    createCardClickEvents(map);
  };

  const options = {
    timeout: 10000,
  };

  navigator.geolocation.getCurrentPosition(
    successCallback,
    errorCallback,
    options
  );

  return map;
}

function createCardClickEvents(map) {
  const $shopCards = document.querySelectorAll('.shop__card');

  $shopCards.forEach((card) => {
    const marker = map.markers[card.dataset.shopId];
    card.addEventListener('mouseup', () => {
      google.maps.event.trigger(marker, 'mousedown');
    });
  });
  map.markers.forEach((marker) => {
    google.maps.event.addListener(marker, 'mousedown', function() {
      map.markers.forEach(mapMarker => mapMarker.setIcon(getIcon(defaultIconColour)));
      marker.setIcon(getIcon(clickedIconColour));
    });
  });
}

function initAllMarkers($markers, map, infowindow, mapId, toLatLng = null) {
    $markers.forEach(marker => {
      initMarker( marker, map, infowindow);
      if (toLatLng !== null) {
        getDistance({lat: marker.dataset.lat, lng: marker.dataset.lng}, toLatLng)
          .then(response => {
            let markerCard = document.querySelector(`[data-shop-id="${marker.dataset.shopId}"]`);
            if (markerCard) {
              markerCard.style.order = response.toFixed();
            }
            var $shopDistance = document.querySelector(`.shop__distance--${marker.dataset.shopId}--${mapId}`);
            if ($shopDistance) {
              $shopDistance.innerHTML = '<p>' + response.toFixed() + '<br>mi</p>';
              $shopDistance.classList.add('d-flex');
            }
          });
      }
    });

    return map;
}
/**
* initMarker
*
* Creates a marker for the given jQuery element and map.
*
* @date    22/10/19
* @since   5.8.6
*
* @param   nodeItem $el The jQuery element.
* @param   object The map instance.
* @param   object The infoWindow instance.
* @return  object The marker instance.
*/
function initMarker( $marker, map, infowindow ) {
  // Get position from marker.
  var lat = $marker.dataset.lat;
  var lng = $marker.dataset.lng;
  var shopId = $marker.dataset.shopId;
  var latLng = {
    lat: parseFloat( lat ),
    lng: parseFloat( lng )
  };
  console.log({shopId, latLng});
  let markerColour = defaultIconColour;
  
  if (shopId == 0) {
    markerColour = '#F6CF00';
  }

  // Create marker instance.
  var marker = new google.maps.Marker({
    position : latLng,
    map: map,
    icon: getIcon(markerColour),
  });
  
  // Append to reference for later use.
  map.markers[shopId] = marker;
  
  // If marker contains HTML, add it to an infoWindow.
  if( $marker.innerHTML ){
    
    // Create info window.
    
    // Show info window when marker is clicked.
    google.maps.event.addListener(marker, 'mousedown', function() {
      infowindow.setContent($marker.innerHTML);
      map.panTo(marker.position);
      infowindow.setOptions({pixelOffset: new google.maps.Size(210, 480)});
      infowindow.open({ map, anchor: marker });
    });
  }
}

function getIcon(markerColour) {
   return {
    path: 'M11.5.4297C5.1238.4297 0 5.7117 0 12.2895 0 16.8498 5.5309 25.9737 8.9214 31.1382 10.157 33.0205 12.843 33.0205 14.0786 31.1382 17.469 25.9737 23 16.8498 23 12.2895 23 5.7117 17.8762.4297 11.5.4297ZM11.5 17.6787C8.5735 17.6787 6.2724 15.3075 6.2724 12.2874 6.2724 9.2695 8.5716 6.8963 11.5 6.8963 14.4265 6.8963 16.7277 9.2674 16.7277 12.2874 16.7277 15.3074 14.4265 17.6787 11.5 17.6787Z',
    fillColor: markerColour,
    size: new google.maps.Size(22, 34),
    origin: new google.maps.Point(0, 0),
    fillOpacity: 1,
    anchor: new google.maps.Point(11, 34),
  };
}

/**
* centerMap
*
* Centers the map showing all markers in view.
*
* @date    22/10/19
* @since   5.8.6
*
* @param   object The map instance.
* @return  void
*/
function centerMap( map ) {
  
  // Create map boundaries from all map markers.
  var bounds = new google.maps.LatLngBounds();
  const visibleMarkers = map.markers.filter(marker => {
    return marker.getVisible();
  })
  visibleMarkers.forEach(function( marker ){
    if (marker.getVisible()) {
      bounds.extend({
        lat: marker.position.lat(),
        lng: marker.position.lng()
      });
    }
  });
  
  // Case: Single marker.
  if( Object.keys(visibleMarkers).length == 1 ){
    map.panTo( bounds.getCenter() );
    map.setZoom(10);
    // Case: Multiple markers.
  } else{
    map.fitBounds( bounds );
  }
}

function filterMarker(map, componentId, query = null) {
  document.querySelector(`#${componentId}-feedback`).classList.remove('d-block');
  document.querySelector(`#${componentId}-feedback`).classList.add('d-none');
  let params = new URLSearchParams();
  params.append('action', 'shop_filtering');
  let searchLocation = userLocation;
  if (query !== null) {
    params.append('lat', query.lat);
    params.append('lng', query.lng);
    searchLocation = { lat: query.lat, lng: query.lng };
  }
  axios.post('/wp-admin/admin-ajax.php', params).then(resp => {
    const shops = resp.data.map((shop) => {
      return shop.ID;
    });
    const $shopCards = document.querySelectorAll(`[data-card-component-id="${componentId}"]`);
    if (shops.length > 0) {
      $shopCards.forEach(card => {
        const marker = map.markers[card.dataset.shopId];
        
        const foundShop = shops.findIndex((shop) => {
          return shop === card.dataset.shopId;
        });

        marker.setIcon(getIcon(defaultIconColour));

        if (searchLocation !== null) {
          getDistance({lat: marker.position.lat(), lng: marker.position.lng()}, searchLocation)
            .then(response => {
              let markerCard = document.querySelector(`[data-shop-id="${card.dataset.shopId}"]`);
              if (markerCard) {
                markerCard.style.order = response.toFixed();
              }
              var $shopDistance = document.querySelector(`.shop__distance--${card.dataset.shopId}--${componentId}`);
              if ($shopDistance) {
                $shopDistance.innerHTML = '<p>' + response.toFixed() + '<br>mi</p>';
                $shopDistance.classList.add('d-flex');
              }
            });
        } else {
          let markerCard = document.querySelector(`[data-shop-id="${card.dataset.shopId}"]`);
          if (markerCard) {
            markerCard.style.order = foundShop;
          }
          var $shopDistance = document.querySelector(`.shop__distance--${card.dataset.shopId}--${componentId}`);
          if ($shopDistance) {
            $shopDistance.classList.remove('d-flex');
          }
        }
        if (foundShop !== -1) {
          marker.setVisible(true);
          card.hidden = false;
        } else {
          marker.setVisible(false);
          card.hidden = true;
        }
      });
    } else {
      $shopCards.forEach(card => {
        const marker = map.markers[card.dataset.shopId];
        marker.setVisible(false);
        card.hidden = true;
      });
      document.querySelector(`#${componentId}-feedback`).classList.remove('d-none');
      document.querySelector(`#${componentId}-feedback`).classList.add('d-block');
    }
    centerMap(map);
  })
}

// Render maps on page load.
document.addEventListener("DOMContentLoaded", function() {
  const allMaps = [];
  const $acfMaps = document.querySelectorAll('.shop__map');

  const initMaps = [...$acfMaps].map((map) => new Promise((resolve) => {
    initMap(map, map.dataset.mapComponentId).then((initialisedMap) => {
      allMaps[map.dataset.mapComponentId] = initialisedMap;
      resolve();
    });
  }));

  Promise.allSettled(initMaps).then((results) => {
    const $shopSearchForms = document.querySelectorAll('form.shop__search-form');
    $shopSearchForms.forEach(form => {
      form.addEventListener('submit', (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        const dataEntries = Object.fromEntries(data.entries());
        const map = allMaps[dataEntries.mapComponentId];
        if (dataEntries.address !== '') {
          getGeocodeResult(dataEntries.address).then(response => {
            if (response.results.length > 0) {
              const latlng = response.results[0].geometry.location;
              if (map.markers[0]) {
                map.markers[0].setVisible(true);
                map.markers[0].setPosition(latlng);
              } else {
                initMarker(
                  {
                    dataset: {
                      shopId: 0,
                      lat: latlng.lat(),
                      lng: latlng.lng(),
                    }
                  },
                  map
                );
              }
              filterMarker(map, dataEntries.mapComponentId, {lat: latlng.lat(), lng: latlng.lng()});
            }
          }).catch(err => {
            console.log(err);
            document.querySelector(`#${dataEntries.mapComponentId}-feedback`).classList.remove('d-none');
            document.querySelector(`#${dataEntries.mapComponentId}-feedback`).classList.add('d-block');
          });
        } else {
          // reset list
          if (userLocation !== null) {
            if (map.markers[0]) {
              map.markers[0].setPosition(new google.maps.LatLng(userLocation.lat, userLocation.lng));
              map.markers[0].setVisible(true);
            }
          } else {
            if (map.markers[0]) {
              map.markers[0].setVisible(false);
            }
          }
          filterMarker(map, dataEntries.mapComponentId);
        }
      })
    });
  });
});
