import axios from 'axios';

const event_type_filter = document.querySelector('#filter_upcoming_events');
const events_list = document.querySelector('.debra-store__upcoming-member-event-list');

event_type_filter?.addEventListener('change', () => {
    
    var selected_event_type = event_type_filter.value;

    let params = new URLSearchParams();
    params.append('action', 'filter_events');  // lib/functions/member-events-filering.php
    params.append('event_type', selected_event_type);

    // Show the loader
    events_list.innerHTML = '<div class="dots-animation"></div>';

    axios.post('/wp-admin/admin-ajax.php', params)
        .then(res => {
            
            // clear the loader then show then results
            events_list.innerHTML = '';
            events_list.innerHTML = res.data.data;
        })
        .catch(error => {
            console.error('Error:', error);
        });
});