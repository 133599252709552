const popularTopicsAudienceSelect = document.getElementById('popularTopicsAudience');
const popularTopicsSubjectSelect = document.getElementById('popularTopicsSubject');
const popularTopicsSubmit = document.getElementById('popularTopicsSubmit');
const popularTopics = document.getElementById('popularTopics');


// Update subject menu based on audience selection
function updateOptions() {
    popularTopicsSubjectSelect.innerHTML = '';

    const selectedAudience = popularTopicsAudienceSelect.value;

    if (options) {
        options[selectedAudience].forEach(option => {
            const optionElement = document.createElement('option');
            optionElement.textContent = option[0];
            optionElement.dataset.page = option[1];
            popularTopicsSubjectSelect.appendChild(optionElement);
        });
    } else {
        console.log('Error: unable to find options' + options)
    }
}

// Init on load / change of select
document.addEventListener("DOMContentLoaded", function() {
    if (popularTopicsAudienceSelect) {
        popularTopicsAudienceSelect.addEventListener('change', updateOptions);

        updateOptions();
    }
});

// Redirect on submit
function redirectPage(event) {
    event.preventDefault();

    redirectPath = popularTopicsSubjectSelect.options[popularTopicsSubjectSelect.selectedIndex].dataset.page;

    window.location.replace(redirectPath);
}

if (popularTopics) {
    popularTopics.addEventListener('submit', redirectPage);
}