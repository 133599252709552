const primaryNav = document.querySelector(".site-header__primary-nav");
const primaryNavItem = primaryNav.querySelectorAll(".menu-item-has-children");
const megaMenus = primaryNav.querySelectorAll(".sub-menu");

primaryNavItem.forEach(primaryNavItem => {  
    var navItemId = primaryNavItem.id.replace(/^[^\d]+/, "");
    var navItemMegamenu = document.querySelector("#primary-nav-megamenu-"+ navItemId +"");
    var navItemLink = primaryNavItem.querySelector("a");

    navItemLink.addEventListener('click', function(e) {
        e.preventDefault();

        currentMegaMenu = navItemMegamenu;
        currentMenuItem = primaryNavItem;

        closeOtherMenus(currentMegaMenu)
        toggleMegaMenu(currentMegaMenu)

        toggleActiveMenuItem(currentMenuItem)
        resetOtherMenuItems(currentMenuItem)
    });
});

// Toggle the mega menu
function toggleMegaMenu(megaMenu) {
    megaMenu.classList.toggle("sub-menu--active");
}

// Close all other mega menus
function closeOtherMenus(currentMegaMenu) {
    megaMenus.forEach(megaMenu => {
        if (megaMenu !== currentMegaMenu) {
            megaMenu.classList.remove("sub-menu--active");
        }
    });
}

// Toggle the active menu item class
function toggleActiveMenuItem(primaryNavItem) {
    primaryNavItem.classList.toggle("menu-item--active")
    primaryNavItem.setAttribute("aria-expanded", "true");
}

// Remove active class from other menu items
function resetOtherMenuItems(currentMenuItem) {
    primaryNavItem.forEach(primaryNavItem => {
        if (primaryNavItem !== currentMenuItem) {
            primaryNavItem.classList.remove("menu-item--active");
            primaryNavItem.setAttribute("aria-expanded", "false");
        }
    });
}


// Back button - closes sub-menu on smaller devices
function megaMenuBackButton() {
    megaMenus.forEach(megaMenu => {
        var megaMenuId = megaMenu.id.replace(/^[^\d]+/, "");
        var backBtn = document.querySelector("#primary-nav-megamenu-back-"+ megaMenuId +"");
        var megaMenuItem = document.querySelector("#primary-nav-megamenu-"+ megaMenuId +"");

        backBtn.addEventListener('click', function(e) {
            e.preventDefault();

            megaMenu.classList.remove("sub-menu--active");
            megaMenuItem.classList.remove("menu-item--active");
        });
    });
}
megaMenuBackButton()


// Close the active mega menu on press of Esc.
document.addEventListener('keydown', (event) => {  
    if (event.key === 'Escape') {
        megaMenus.forEach(megaMenu => {
            if ( megaMenu.classList.contains("sub-menu--active") ) {
                megaMenu.classList.remove("sub-menu--active");
            }
        });

        primaryNavItem.forEach(primaryNavItem => {
            if (primaryNavItem !== currentMenuItem) {
                primaryNavItem.classList.remove("menu-item--active");
                primaryNavItem.setAttribute("aria-expanded", "false");
            }
        });
    }
});