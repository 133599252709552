import axios from 'axios';

const orderFilter = document.querySelector('.search-results-page__order-filter');
const tagFilter = document.querySelectorAll('.search-results-page__tag-filter');

var searchResultsInput = document.querySelector('.search-results-page__form-input');
// if the SearchWP plugin is active, the search input class changes
if ( document.querySelector('.swp-input--search') ) {
    var searchResultsInput = document.querySelector('.swp-input--search');
}

const searchResultsContainer = document.querySelector('.search-results-page__results-listing');

let currentUrl = window.location.href
let current_page = searchResultsContainer?.dataset.page;
let max_pages = searchResultsContainer?.dataset.max;
let per_page = searchResultsContainer?.dataset.perpage;

const allCount = document.querySelector('.search-results-page__listing-heading h2');

const ajaxSearchResults = () => {
    document.addEventListener('DOMContentLoaded', function() {
        if ( tagFilter ) {
            filterPostsByTag();
        }
        if ( searchResultsContainer ) {
            ajaxPaginate();
        }
        if ( orderFilter ) {
            sortPostsBySelectedOrder();
        }
    });
}

function sortPostsBySelectedOrder(){
    orderFilter?.addEventListener('input', () => {
        current_page = 1;
        const params = buildQuery(searchResultsInput.value);
        executeQuery(params);
    });
}

let filterPostsBySearch = debounce(function(event) {
    event.preventDefault();
    current_page = 1;
    const searchQuery = event.target.value;
    const params = buildQuery(searchQuery);
    executeQuery(params)
}, 500)

// Filter posts by tag
function filterPostsByTag() {
    tagFilter.forEach(tag => {

        tag.addEventListener('click', debounce(function() {
            current_page = 1;
            const searchQuery = searchResultsInput.value; // Get the current search query
            const params = buildQuery(searchQuery);
            executeQuery(params);
        }, 500));
    });
}

// Build the query
function buildQuery(searchQuery) {
    let params = new URLSearchParams();

    tagFilter?.forEach(tag => {
        if (tag.checked) {
            params.append('tag[]', tag.value);
        }
    });

    params.append('q', searchQuery);
    if(searchQuery !== ''){
        params.append('base_url', currentUrl + `?q=${searchQuery}&`);
    }

    return params
}

// Execute the query
function executeQuery(params) {
    params.append('action', 'search_filtering'); // lib/functions/search-results-filering.php

    params.append('current_page', current_page);
    params.append('max_pages', max_pages);
    params.append('per_page', per_page);

    params.append('order_by', orderFilter.value);

    // Show loading message
    searchResultsContainer.innerHTML = '<div class="search-results-page__loading"><div class="dots-animation"></div></div>';

    axios.post('/wp-admin/admin-ajax.php', params)
        .then(resp => {

            if (resp.data.html) {
                searchResultsContainer.innerHTML = '';
                searchResultsContainer.innerHTML = resp.data.html;
            }

            if (resp.data.allCount !== undefined) {
                let resultText = resp.data.allCount === 1 ? "Result" : "Results";
                allCount.innerHTML = resp.data.allCount + " " + resultText;
            }

            ajaxPaginate();
        }).catch(error => {
            console.error('Error:', error);
        });
}

function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

// Pagination
function ajaxPaginate(){
    const pageNumbers = document.querySelectorAll('.search-results__pagination a .page-numbers');
    pageNumbers.forEach(pageNumber => {
        pageNumber.addEventListener('click', (event) => {
            event.preventDefault();
            const $currentPageEl = Array.from(pageNumbers).find(item => {
                return item.classList.contains('current');
            });
            const currentPage = $currentPageEl.innerHTML;

            $currentPageEl.classList.remove('current');

            if(event.target.classList.contains('next')){
                current_page = parseInt(currentPage) + 1
            }else if(event.target.classList.contains('prev')){
                current_page = parseInt(currentPage)- 1
            } else{
                event.target.classList.add('current');
                current_page = event.target.innerHTML;
            }
            const params = buildQuery(searchResultsInput.value)
            executeQuery(params);

        })
    });
}

ajaxSearchResults();
