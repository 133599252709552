// Swiper.js
import Swiper from 'swiper';
import { Navigation, Pagination, A11y } from 'swiper/modules';

const carouselSwiper = new Swiper('.carousel__swiper', {
    modules: [Navigation, Pagination, A11y],
    loop: true, 
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    autoHeight: true, 
    pagination: {
        el: '.carousel__swiper-pagination', 
        clickable: true
    },
    navigation: {
        nextEl: '.carousel__swiper-btn-next', 
        prevEl: '.carousel__swiper-btn-prev',
    }, 
    a11y: {
        enabled: true, 
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
    },
});


const testimonialsCarouselSwiper = new Swiper('.testimonials-carousel__swiper', {
    modules: [Navigation, Pagination, A11y],
    loop: true, 
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    autoHeight: true, 
    pagination: {
        el: '.testimonials-carousel__swiper-pagination', 
        clickable: true
    },
    navigation: {
        nextEl: '.testimonials-carousel__swiper-btn-next', 
        prevEl: '.testimonials-carousel__swiper-btn-prev',
    }, 
    a11y: {
        enabled: true, 
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
    },
});