const donateCta = document.querySelector('.donate-cta');


if ( donateCta ) {

    const donateToggles = document.querySelectorAll('.donate-cta__type-toggle');
    const donateOptions = document.querySelectorAll('.donate-cta__options-group');
    const donateButtons = document.querySelectorAll('.donate-cta__amount-button');
    const donateInputs = document.querySelectorAll('.donate-cta__amount-input');
    const donateSubmit = document.querySelector('.donate-cta__button');

    const donateUrlField = document.getElementById('donateUrl');
    const donateUrlMonthlyField = document.getElementById('donateUrlMonthly');
    const donateAmountField = document.getElementById('donateAmount');
    const donateFreqField = document.getElementById('donateFrequency');

    // Handle the type switching and set the frequency field
    donateToggles?.forEach(toggle => {

        toggle.addEventListener('click', (e) => {
            e.preventDefault();

            let donationType = toggle.dataset.donationtype;

            donateToggles?.forEach(donateToggle => {
                donateToggle.classList.remove('donate-cta__type-toggle--active');
            })
            toggle.classList.add('donate-cta__type-toggle--active');

            donateOptions?.forEach(option => {
                option.classList.remove('donate-cta__options-group--active');

                if ( option.dataset.donationtype == donationType ) {
                    option.classList.add('donate-cta__options-group--active');
                }
            })

            donateFreqField.value = donationType;

            updateDonateUrl()

        });
    });

    // Set the amount field from the buttons
    donateButtons?.forEach(button => {
        
        button.addEventListener('click', (e) => {
            e.preventDefault();

            const donationAmount = button.dataset.donationamount;

            donateInputs?.forEach(donateInput => {
                donateInput.classList.remove('donate-cta__amount-input--active');
            })

            donateButtons?.forEach(donateButtons => {
                donateButtons.classList.remove('donate-cta__amount-button--active');
            })
            button.classList.add('donate-cta__amount-button--active');

            donateAmountField.value = donationAmount;

            updateDonateUrl()
        });
    });

    // Set the amount field from the inputs
    donateInputs?.forEach(input => {
        
        input.addEventListener('keyup', () => {

            const donationAmount = input.value;

            donateButtons?.forEach(donateButtons => {
                donateButtons.classList.remove('donate-cta__amount-button--active');
            })

            donateInputs?.forEach(donateInput => {
                donateInput.classList.remove('donate-cta__amount-input--active');
            })
            input.classList.add('donate-cta__amount-input--active');
            

            donateAmountField.value = donationAmount;

            updateDonateUrl()
        });
    });


    // Build the URL
    function updateDonateUrl() {

        const donateBase = donateUrlField.dataset.baseurl;
        const donateBaseMonthly = donateUrlMonthlyField.dataset.baseurl;

        if (donateFreqField.value == 'Single') {
            donateUrlField.value = donateBase + '?frequency=' + donateFreqField.value + '&amount=' + donateAmountField.value;
        } else {
            donateUrlMonthlyField.value = donateBaseMonthly + '?frequency=' + donateFreqField.value + '&amount=' + donateAmountField.value;
        }
    }

    donateSubmit.addEventListener('click', (e) => {
        e.preventDefault();

        if (donateFreqField.value == 'Single') {
            // Check the single amount is more than 1.00
            validationMsg = document.querySelector('.donate-cta__amount-input-single-error');

            if (donateAmountField.value < 2.00 ) {
                validationMsg.classList.add('donate-cta__amount-input-single-error--active');
            } else {
                validationMsg.classList.remove('donate-cta__amount-input-single-error--active');
                window.location = donateUrlField.value;
            }
        } else {
            // Check the monthly amount is more than 2.00
            validationMsg = document.querySelector('.donate-cta__amount-input-monthly-error');

            if (donateAmountField.value < 2.00 ) {
                validationMsg.classList.add('donate-cta__amount-input-monthly-error--active');
            } else {
                validationMsg.classList.remove('donate-cta__amount-input-monthly-error--active');
                window.location = donateUrlMonthlyField.value;
            }
        }
    });


    // Prevent non-numeric entry in the 'other amount' fields (allow decial)
    donateInputs?.forEach(donateInput => {
        donateInput.addEventListener('keypress', (evt) => {
            if ( evt.which != 46 && (evt.which < 48 || evt.which > 57) ) {
                evt.preventDefault();

                console.log(evt.which)
            }
        });
    });
}