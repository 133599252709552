import { Modal } from 'bootstrap';

const galleryWrapper = document.querySelector('.woocommerce-product-gallery__wrapper');
const galleryImages = document.querySelectorAll('.woocommerce-product-gallery__image');
const galleryModal = document.querySelector('.debra-store__product-gallery-modal');

if ( galleryWrapper && galleryModal ) {
    
    galleryImages?.forEach(galleryImage => {

        const galleryImageAnchor = galleryImage.querySelector('a');
        const galleryImageLink = galleryImageAnchor.getAttribute("href");
        const galleryModalContent = '<img src="' + galleryImageLink + '" alt="">';

        galleryImageAnchor.addEventListener('click', (e) => {
            e.preventDefault();

            showModal({
                value: galleryModalContent,
            });

        });
    })
}


const showModal = (data) => {

    galleryModal.innerHTML = '';

    galleryModal.innerHTML = ` <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">    
                ${data.value}
            </div>
        </div>
    </div> `;

    var initModal = new Modal(galleryModal);
    initModal.show();
};