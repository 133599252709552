/* Accessibility Code for "www.debra.org.uk" 
Want to customize your button? visit our documentation page:
https://login.equalweb.com/custom-button.taf
*/
window.interdeal = {
    "sitekey": "bf385cfd2a1087708ea7f3bce2485cdd",
    "Position": "Left",
    "Menulang": "EN-GB",
    "domains": {
        "js": "https://cdn.equalweb.com/",
        "acc": "https://access.equalweb.com/"
    },
    "btnStyle": {
        "vPosition": [
            "50%",
            "20%"
        ],
        "scale": [
            "0.8",
            "0.5"
        ],
        "color": {
            "main": "#302683",
            "second": "#f6cf01"
        },
        "icon": {
            "type": 13,
            "shape": "semicircle"
        }
    }
};
(function(doc, head, body){
    var coreCall             = doc.createElement('script');
    coreCall.src             = 'https://cdn.equalweb.com/core/4.6.11/accessibility.js';
    coreCall.defer           = true;
    coreCall.integrity       = 'sha512-SVffVpbO/SKg8zoE3NWu0Ct32mTRDO5b27N4qA5C+e6ctRHVwAk158RdBWMlaD2UuAtQUxeRwY71joiCkwsFVA==';
    coreCall.crossOrigin     = 'anonymous';
    coreCall.setAttribute('data-cfasync', true );
    body? body.appendChild(coreCall) : head.appendChild(coreCall);
})(document, document.head, document.body);